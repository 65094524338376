import React from "react";
import { Flex, Box, Text, Heading, Image } from "@chakra-ui/react";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Nav from "../components/Nav";
import Seo from "../components/Seo";

import contactUsTags from "./contactUsTags.json";
//@ts-ignore
import amonite from "../images/testimonials/amonite.svg";
//@ts-ignore
import bgtestimonials from "../images/testimonials/metatron.svg";

export default function contact_us() {
  return (
    <>
      <Seo
        title={contactUsTags.title}
        description={contactUsTags.description}
        url={contactUsTags.url}
      />
      <Main>
        <Nav active="contact-us" />
        <Flex
          w="100%"
          justifyContent="space-evenly"
          alignItems="center"
          wrap="wrap"
          my="2rem"
          h={["130vh", "140vh", "120vh", "100vh", "100vh"]}
          backgroundImage={bgtestimonials}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"contain"}
          backgroundPosition={["-30rem", "-30rem", "-30rem", "-15rem"]}
          px="2rem"
          className="amoniteHead"
        >
          <Flex
            alignItems={"center"}
            justifyContent="center"
            flexDir="column"
            maxW="600px"
            w={["100%", "100%", "40%"]}
          >
            <Heading
              color="#06667C"
              fontSize="3rem"
              fontFamily="Montserrat"
              className="services"
            >
              Contact Us
            </Heading>
          </Flex>
          <Flex
            w={["100%", "100%", "100%", "40%", "40%", "40%"]}
            h="100%"
            justifyContent={"center"}
            alignItems="center"
          >
            <iframe
              src="https://tally.so/embed/wzx2PR?alignLeft=1&hideTitle=1&transparentBackground=1"
              width="100%"
              height="600px"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="Find out more"
            ></iframe>
          </Flex>
        </Flex>

        <Footer />
      </Main>
    </>
  );
}
